import { RoleTypeSM } from "../service/app/enums/role-type-s-m.enum";

export class LayoutViewModel {
    stickyMenu: boolean = false;
    elementPosition: any;
    showTopNav: boolean = false;
    showFooter: boolean = false;
    showLoadingBtn: boolean = false;
    showAdmintoptNav: boolean = false;
    // invalidFormDetails: boolean = false;
    isAuthenticated: boolean = false;
    roleType: RoleTypeSM = RoleTypeSM.Unknown;
    solutionEngineering = "servicesListDropdown";
    strategy = "servicesListDropdown";
    projectManagement = "servicesListDropdown";
    microsoft365 = "servicesListDropdown";
    cyberSecurity = "servicesListDropdown";
    softwareMaintenance = "servicesListDropdown";
    dataDriven = "servicesListDropdown";
}