import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { LoaderInfo } from '../models/internal/common-models';
import { LayoutViewModel } from '../models/view/layout.viewmodel';
import { Observable, ReplaySubject } from 'rxjs';
import { SweetAlertIcon, SweetAlertOptions } from '../models/internal/custom-sweet-alert-options';
declare var Swal: any;

@Injectable({
  providedIn: 'root'
})

export class CommonService extends BaseService {

  layoutViewModel: LayoutViewModel = new LayoutViewModel();
  loaderInfo: LoaderInfo = { message: '', showLoader: false };

  constructor() {
    super();
  }


  async presentLoading(message: string = '') {
    await setTimeout(async () => {
      this.loaderInfo = { message, showLoader: true };
      this.layoutViewModel.showFooter = false;
      this.layoutViewModel.showTopNav = false;
    }, 100);
 
  }


  async presentAlert() {

  }


  async dismissLoader(resetHeaderFooter = true) {
    await setTimeout(async () => {
      this.loaderInfo.showLoader = false;
    this.loaderInfo.message = '';
    if (resetHeaderFooter) {
      this.layoutViewModel.showFooter = true;
      this.layoutViewModel.showTopNav = true;
    }
    }, 400);
    
  }


  async showInfoModalPopup(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 2000
    })
  }

  async showInfoModalPopupWithConfirmation(options: SweetAlertOptions) {
    options.position = "center";
    options.showConfirmButton = true;
    options.timerProgressBar = false;
    options.didOpen = (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    };
    return await Swal.fire(options);
  }


  async showSweetAlertConfirmation(alertOptions: SweetAlertOptions) {
    return (await Swal.fire(alertOptions)).isConfirmed;
  }
  // Convert file to Base64
  convertFileToBase64(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event: any) => result.next(btoa(event.target.result.toString()));
    return result;
  }
  async showSweetAlertToast(alertOptions: SweetAlertOptions) {
    alertOptions.toast = true;
    alertOptions.position = "bottom";
    alertOptions.showConfirmButton = false;
    alertOptions.timer = 3000;
    alertOptions.timerProgressBar = true;
    alertOptions.didOpen = (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    };
    return await Swal.fire(alertOptions);
  }

  downloadDocument(base64data: string, fileExtension: string, fileName: string) {
    var blob = this.convertFromBase64ToPDF(base64data, "application/docx");
    let a = document.createElement("a");
    document.body.appendChild(a);
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = String(fileName + "." + fileExtension);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public convertFromBase64ToPDF(b64Data: string, contentType: any) {
    contentType = contentType || "";
    let sliceSize = 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  enumToStringArray(LicenseType: any) {
    const StringIsNumber = (value: any) => isNaN(Number(value)) === false;
    return Object.keys(LicenseType)
      .filter(StringIsNumber)
      .map((key) => LicenseType[key]);
  }

}
